<template>
  <v-card id="account-setting-card">
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        {{ $t("type_book") }}/{{ $t("time") }}
      </v-tab>
      <v-tab>
        {{ $t("time") }} -
        {{ $t("show_appointments") }}
      </v-tab>
      <v-tab>
        {{ $t("holidayClinic") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <BookTopic></BookTopic>
      </v-tab-item>
      <v-tab-item>
        <TimeAppointments></TimeAppointments>
      </v-tab-item>
      <v-tab-item>
        <Holiday />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import useTopicAppointmentAndTime from './useTopicAppointmentAndTime'
import BookTopic from './BookTopic.vue'
import Holiday from './Holiday.vue'
import TimeAppointments from './TimeAppointments.vue'

export default {
  components: {
    BookTopic,
    TimeAppointments,
    Holiday,
  },
  setup() {
    const { tab } = useTopicAppointmentAndTime()

    return { tab }
  },
}
</script>

<style lang="scss" scoped>

</style>
