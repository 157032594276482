<template>
  <div>
    <v-card>
      <v-card-title>
        <span>{{ $t('holidayClinic') }} </span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="isAddNewHoliday = true"
        >
          {{ $t('addHoliday') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :footer-props="footer"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #[`item.id`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template #[`item.shop_holiday_day`]="{ item }">
          {{ item.shop_holiday_day | sumdate }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            color="primary"
            icon
            @click="dataEdit = item;isEditHoliday = true"
          >
            <v-icon>
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            @click="dataEdit = item;isDelete = true"
          >
            <v-icon>
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <AddNewHoliday
      v-model="isAddNewHoliday"
      @onAdd="fetchDataTable"
    />
    <EditHoliday
      v-model="isEditHoliday"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-dialog
      v-model="isDelete"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          {{ $t('confirm_deletion') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="deleteHoliday"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDelete =false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import useHoliday from './useHoliday'
import Pagination from '@/components/basicComponents/Pagination.vue'
import AddNewHoliday from './AddNewHoliday.vue'
import { sumdate } from '@/plugins/filters'
import EditHoliday from './EditHoliday.vue'

export default {
  filters: {
    sumdate,
  },
  components: {
    Pagination,
    AddNewHoliday,
    EditHoliday,
  },
  setup() {
    return {
      ...useHoliday(),
    }
  },
}
</script>
