<template>
  <div>
    <v-data-table
      :headers="columns1"
      :items="dataTableList1"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.section_id`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.section_status_id`]="{ item }">
        <v-chip
          small
          :class="item.section_status_id == 1 ? 'v-chip-light-bg primary--text' : 'v-chip-light-bg error--text'"
        >
          <v-icon
            small
            left
            :color="item.section_status_id == 1 ? 'primary' : 'error'"
          >
            {{ item.section_status_id == 1 ? icons.mdiCheck : icons.mdiClose }}
          </v-icon>
          {{ item.section_status_id == 1 ? $t('open') : $t('close') }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-switch
          v-model="item.section_status_id"
          :true-value="1"
          :false-value="2"
          hide-details
          :loading="statusLoading"
          class="py-0 my-0"
          :disabled="statusLoading"
          @change="update_status(item)"
        >
        </v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiCheck, mdiClose } from '@mdi/js'
import bookTopic from '@/api/appointment/bookTopic'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  setup() {
    const timeSelect = ref('')

    const dataTableList1 = ref([])
    const loading = ref(false)
    const options = ref({})
    const statusLoading = ref(false)
    const columns1 = ref([
      {
        text: '#', align: 'start', value: 'section_id', width: 70,
      },
      { text: i18n.t('time'), value: 'section_time' },
      { text: i18n.t('status'), value: 'section_status_id' },
      { text: i18n.t('option'), value: 'actions' },

    ])

    const { timeList, timeUpdate } = bookTopic

    onMounted(() => {
      getTimeList()
    })

    const getTimeList = () => {
      loading.value = true
      timeList().then(res => {
        dataTableList1.value = res
        options.value.page = 1
        options.value.itemsPerPage = -1
        loading.value = false
      })
    }

    const update_status = data => {
      statusLoading.value = true
      const body = {
        section_time: data.section_time,
        section_sort: data.section_sort,
      }
      let path = ''
      if (data.section_status_id == 1) {
        path = 'book/section'
      } else {
        path = 'book/sectionstatus'
      }
      timeUpdate(path, body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getTimeList()
        statusLoading.value = false
      })
    }

    return {
      statusLoading,
      update_status,
      dataTableList1,
      loading,
      columns1,
      options,
      timeSelect,
      timeList,
      icons: {
        mdiCheck, mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
