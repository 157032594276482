<template>
  <div>
    <v-dialog
      v-model="isEditBookTopic"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_topic_appointment') }}
        </v-card-title>
        <v-form
          ref="formEditBookTopic"
          @submit.prevent="updateBookTopic"
        >
          <v-card-text>
            <v-text-field
              v-model="shopName"
              outlined
              dense
              :label="$t('clinic_name')"
            ></v-text-field>
            <v-text-field
              v-model="dataEditLocal.book_name"
              outlined
              :rules="[required]"
              class="mt-3"
              dense
              :label="$t('title_list_appointment')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-book-topic', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import bookTopic from '@/api/appointment/bookTopic'
import store from '@/store'

export default {
  model: {
    prop: 'isEditBookTopic',
    event: 'update:is-edit-book-topic',
  },
  props: {
    isEditBookTopic: { type: Boolean, default: false },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formEditBookTopic = ref(null)
    const shopName = ref(localStorage.getItem('shop_name'))
    const loading = ref(false)
    const dataEditLocal = ref({})

    const { bookTopicUpdate } = bookTopic
    const updateBookTopic = () => {
      const isFormValid = formEditBookTopic.value.validate()
      if (!isFormValid) return
      loading.value = true
      bookTopicUpdate(dataEditLocal.value)
        .then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })
          emit('update:is-edit-book-topic', false)
          loading.value = false
          emit('onUpdate')
        })
    }
    watch(() => props.dataEdit, newvalue => {
      if (newvalue) {
        dataEditLocal.value = JSON.parse(JSON.stringify(newvalue))
      }
    })

    return {
      formEditBookTopic,
      loading,
      dataEditLocal,
      shopName,
      updateBookTopic,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
