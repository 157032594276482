import holiday from '@/api/appointment/holiday'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useHoliday() {
  const loading = ref(false)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      value: 'id',
      width: '50',
    },
    {
      text: i18n.t('topic'),
      value: 'shop_holiday_topic',
    },
    {
      text: i18n.t('date'),
      value: 'shop_holiday_day',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'end',
    },
  ])
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const isAddNewHoliday = ref(false)
  const isEditHoliday = ref(false)
  const dataEdit = ref({})
  const isDelete = ref(false)

  onMounted(async () => {
    fetchDataTable()
  })
  const fetchDataTable = async () => {
    loading.value = true
    await holiday
      .holidayList({
        active_page: options.value.page,
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      })
      .then(res => {
        const { count, count_of_page, data, segment } = res
        dataTableList.value = data
        totalDataTableList.value = count
        totalPage.value = count_of_page
        segmentId.value = segment
        loading.value = false
      })
  }
  const deleteHoliday = () => {
    holiday.holidayRemove(dataEdit.value.shop_holiday_id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      isDelete.value = false
      fetchDataTable()
    })
  }

  watch([options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    fetchDataTable,
    loading,
    options,
    footer,
    columns,
    totalDataTableList,
    segmentId,
    dataTableList,
    isAddNewHoliday,
    isEditHoliday,
    totalPage,
    dataEdit,
    deleteHoliday,
    isDelete,
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
    },
  }
}
