<template>
  <div>
    <v-dialog
      v-model="isEditHoliday"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('addHoliday') }}
        </v-card-title>
        <v-form
          ref="formEditHoliday"
          @submit.prevent="updateHoliday"
        >
          <v-card-text>
            <v-text-field
              v-model="dataEditLocal.shop_holiday_topic"
              hide-details="auto"
              outlined
              dense
              :label="$t('topic')"
              :rules="required"
            ></v-text-field>
            <v-dialog
              ref="date1"
              v-model.trim="modal1"
              :return-value.sync="dataEditLocal.shop_holiday_day"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model.trim="dataEditLocal.shop_holiday_day"
                  :label="$t('date')"
                  outlined
                  dense
                  v-bind="attrs"
                  hide-details="auto"
                  class="mt-6"
                  :rules="required"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="dataEditLocal.shop_holiday_day"
                class=""
                :locale="$i18n.locale"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date1.save(dataEditLocal.shop_holiday_day)"
                >
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn
                  text
                  color="secondary"
                  @click="modal1 = false"
                >
                  {{ $t('cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:isEditHoliday',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { required } from '@/@core/utils/validation'
import { ref, watch } from '@vue/composition-api'
import holiday from '@/api/appointment/holiday'
import store from '@/store'

export default {
  model: {
    prop: 'isEditHoliday',
    event: 'update:isEditHoliday',
  },
  props: {
    isEditHoliday: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const modal1 = ref(false)
    const formEditHoliday = ref(null)
    const loading = ref(false)
    const dataEditLocal = ref({})
    watch(() => props.isEditHoliday, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })
    const updateHoliday = () => {
      const isFormValid = formEditHoliday.value.validate()
      if (!isFormValid) return
      loading.value = true
      holiday.holidayUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:isEditHoliday', false)
        emit('onUpdate')
      })
    }

    return {
      modal1,
      formEditHoliday,
      updateHoliday,
      required,
      dataEditLocal,
    }
  },
}
</script>
