<template>
  <div>
    <v-dialog
      v-model="isAddNewBookTopic"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_topic_appointment') }}
        </v-card-title>
        <v-form
          ref="formAddNewBookTopic"
          @submit.prevent="createBookTopic"
        >
          <v-card-text>
            <v-text-field
              v-model="shopName"
              outlined
              dense
              :label="$t('clinic_name')"
            ></v-text-field>
            <v-text-field
              v-model="book_name"
              outlined
              :rules="[required]"
              class="mt-3"
              dense
              :label="$t('title_list_appointment')"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-book-topic', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import bookTopic from '@/api/appointment/bookTopic'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewBookTopic',
    event: 'update:is-add-new-book-topic',
  },
  props: {
    isAddNewBookTopic: { type: Boolean, default: false },

  },
  setup(props, { emit }) {
    const formAddNewBookTopic = ref(null)
    const book_name = ref('')
    const shopName = ref(localStorage.getItem('shop_name'))
    const loading = ref(false)

    const { bookTopicAdd } = bookTopic
    const createBookTopic = () => {
      const isFormValid = formAddNewBookTopic.value.validate()
      if (!isFormValid) return
      loading.value = true
      bookTopicAdd({
        book_name: book_name.value,
      })
        .then(res => {
          store.commit('app/ALERT', {
            message: res.message,
            color: res.response ? 'success' : 'error',
          })
          emit('update:is-add-new-book-topic', false)
          loading.value = false
          emit('onAdd')
        })
    }
    watch(() => props.isAddNewBookTopic, newvalue => {
      if (newvalue) {
        book_name.value = ''
      }
    })

    return {
      formAddNewBookTopic,
      loading,
      book_name,
      shopName,
      createBookTopic,
      required,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
