<template>
  <div>
    <v-row class="px-2 my-2">
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          v-model="searchtext"
          :label="$t('search')"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model.trim="book_status_id"
          :items="statusList"
          :label="$t('status')"
          :item-text="$i18n.locale"
          outlined
          dense
          item-value="value"
          hide-details
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        align="right"
      >
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewBookTopic = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>  {{ $t('add_topic_appointment') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewBookTopic = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.book_id`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.book_status_id`]="{ item }">
        <StatusBlock :status="item.book_status_id" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="dataEdit = item;isEditBookTopic = true"
              v-on="on"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("edit") }}</span>
        </v-tooltip>
        <v-tooltip
          color="#212121"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              v-bind="attrs"
              @click="book_id = item.book_id;book_status_id_update = item.book_status_id;isUpdateStatus = true"
              v-on="on"
            >
              <v-icon>{{ item.book_status_id ==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.book_status_id ==1?$t("suspend"):$t("normal") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <AddNewBookTopic
      v-model="isAddNewBookTopic"
      @onAdd="fetchDataTable"
    />
    <EditBookTopic
      v-model="isEditBookTopic"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <UpdateStatusBookTopic
      :id="book_id"
      v-model="isUpdateStatus"
      :status-id="book_status_id_update"
      @onUpdate="fetchDataTable"
    />
  </div>
</template>
<script>
import {
  mdiPlus, mdiCheck, mdiDeleteOutline, mdiPencilOutline,
} from '@mdi/js'
import useBookTopic from './useBookTopic'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewBookTopic from './AddNewBookTopic.vue'
import EditBookTopic from './EditBookTopic.vue'
import UpdateStatusBookTopic from './UpdateStatusBookTopic.vue'

export default {
  components: {
    StatusBlock,
    AddNewBookTopic,
    EditBookTopic,
    UpdateStatusBookTopic,
  },
  setup() {
    const {
      book_id,
      book_status_id_update,
      isUpdateStatus,
      isEditBookTopic,
      dataEdit,
      isAddNewBookTopic,
      searchtext,
      book_status_id,
      statusList,

      dataTableList,
      loading,
      columns,
      options,
      fetchDataTable,
    } = useBookTopic()

    return {
      book_id,
      book_status_id_update,
      isUpdateStatus,
      isEditBookTopic,
      dataEdit,
      isAddNewBookTopic,
      searchtext,
      book_status_id,
      statusList,

      dataTableList,
      loading,
      columns,
      options,
      fetchDataTable,

      icons: {
        mdiPlus, mdiCheck, mdiDeleteOutline, mdiPencilOutline,
      },

    }
  },
}
</script>
