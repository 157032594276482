import refreshToken from '@/plugins/refresh-token'
import { api } from '../mainNode'

const holidayList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'holiday/list',
      body,
    })
    .then(res => res || [])
    .catch(err => {
      console.log('get holiday list error: ', err)

      return []
    })

  return response
}
const holidayAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'holiday',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add holiday error: ', err)

      return {}
    })

  return response
}
const holidayUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'holiday',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update holiday error: ', err)

      return {}
    })

  return response
}
const holidayRemove = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'holiday',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update holiday error: ', err)

      return {}
    })

  return response
}
export default {
  holidayList,
  holidayUpdate,
  holidayRemove,
  holidayAdd,
}
