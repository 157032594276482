import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import bookTopic from '@/api/appointment/bookTopic'
import { i18n } from '@/plugins/i18n'

export default function useBookingToppic() {
  const dataTableList = ref([])
  const loading = ref(false)
  const columns = ref([
    {
      text: '#', align: 'start', value: 'book_id', width: 70,
    },
    { text: i18n.t('title_list_appointment'), value: 'book_name' },
    { text: i18n.t('status'), value: 'book_status_id' },
    { text: i18n.t('option'), value: 'actions' },

  ])
  const options = ref({})

  const searchtext = ref('')
  const book_status_id = ref(1)
  const statusList = ref(basicStatus.data)

  const isAddNewBookTopic = ref(false)

  const isEditBookTopic = ref(false)
  const dataEdit = ref({})

  const book_id = ref('')
  const isUpdateStatus = ref(false)
  const book_status_id_update = ref('')

  const { bookTopicList } = bookTopic

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    bookTopicList({
      searchtext: searchtext.value,
      book_status_id: book_status_id.value,
    }).then(res => {
      dataTableList.value = res
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, book_status_id], () => {
    fetchDataTable()
  })

  return {
    book_id,
    book_status_id_update,
    isUpdateStatus,
    isEditBookTopic,
    dataEdit,
    isAddNewBookTopic,
    searchtext,
    book_status_id,
    statusList,

    dataTableList,
    loading,
    columns,
    options,
    fetchDataTable,

  }
}
